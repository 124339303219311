<!--账号密码登录-->
<template>
  <div class="login-com">

    <el-form ref="loginForm" :model="loginForm" :rules="rules">

      <!--手机号-->
      <el-form-item prop="loginName">

        <el-input
          v-model="loginForm.loginName"
          size="large"
          class="login-input"
          placeholder="请输入账号"
          :maxlength="11"
          @change="checkPhone"
          @pressEnter="() => { this.$emit('login') }"
        >
          <GIcon slot="prefix" icon="icon-wodeyongchexuanzhong" />

        </el-input>

      </el-form-item>

      <!--密码-->
      <el-form-item prop="loginPwd">
        <el-input
          v-model="loginForm.loginPwd"
          class="login-input"
          size="large"
          type="password"
          placeholder="请输入密码"
          :max-length="20"
          @pressEnter="() => { this.$emit('login') }"
        >
          <GIcon slot="prefix" icon="icon-mimadenglu" />

        </el-input>
      </el-form-item>

      <!--谷歌验证码-->
      <el-form-item v-if="isShowGoogleCode" prop="googleCode">
        <el-input
          v-model="loginForm.googleCode"
          class="login-input"
          size="large"
          type="password"
          placeholder="请输入Google 6 位数验证码"
          :max-length="6"
          @pressEnter="() => { this.$emit('login') }"
        >
          <GIcon slot="prefix" icon="icon-logo_google" />

        </el-input>
      </el-form-item>

    </el-form>

  </div>
</template>

<script>
import { validationPhone } from '@/utils/validationRules'
export default {
  name: 'AccountLogin',
  props: {
    value: { type: String, default: '' },
    info: { type: Array, default: () => {} },
    defaultValue: { type: Object, default: () => {} }
  },
  data() {
    return {
      loginForm: {
        loginName: '',
        loginPwd: '',
        googleCode: ''
      },
      isShowGoogleCode: false,
      rules: {
        loginName: [
          { required: true, message: '请输入账号' },
          { validator: this.validatorAccount, trigger: 'blur' }
        ],
        loginPwd: [
          { required: true, message: '请输入密码' }
        ]
      },
      imgCodeTime: null
    }
  },
  watch: {
    'defaultValue': function() {
      this.loginForm = this.defaultValue
    }
  },
  created() {
    this.loginForm = this.defaultValue
  },
  methods: {
    // 校验账号
    checkPhone() {
      this.imgCodeTime = validationPhone(this.loginForm.loginName) ? new Date() : ''
    },
    // 校验表单
    validate(callback, errBack) {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          callback(this.loginForm)
        } else {
          errBack()
        }
      })
    },
    // 自定义校验规则
    validatorAccount(rule, value, callback) {
      if (!value) callback()

      if (!/^[0-9a-zA-Z]*$/.test(value)) {
        callback('请输入正确格式的账号')
      }
      callback()
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-com{
    padding-top: 24px;
    .el-form-item{
      margin-bottom: 24px;
    }
    ::v-deep {
      .el-input__prefix {
        line-height: 44px;
        padding-left: 6px;
      }
    }
    .verify-input {
      display: flex;
      align-content: center;
      .ant-input-lg {
        height: 44px;
        line-height: 44px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .img-code {
        cursor: pointer;
        width: 126px;
        min-width: 126px;
        height: 44px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px #d9d9d9 solid;
        border-left: 0;
      }
    }
  }
</style>
